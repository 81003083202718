import React from "react"

const HomePage = () => {
  return (
    <main id="main">
      <p>
        Hi, I am <span className="underline">Alp Kahvecioglu</span> a programmer
        and web developer from Istanbul, Turkey. I am{" "}
        <span className="underline text-bold">currently available</span> for a{" "}
        <span className="underline">Web Developer</span> role in your stack of
        choice or a <span className="underline">Software Developer</span> role{" "}
        in Python.
      </p>
      <p>
        I am proficient in modern web technologies (HTML / CSS / Javascript /
        React / node.JS) and have working knowledge in Python, Golang and Bash.
        Whether you want someone to build an idea you have or a problem that you
        need a fresh set of eyes for please{" "}
        <span className="underline">feel free to email me</span>.
      </p>
      <p>
        I usually enjoy designing and deep-diving into things that are useful or
        informative in some way but sometimes I like to tinker with new
        technologies just for the fun of it.{" "}
      </p>
      <p>
        Most recently, I finished an{" "}
        <span className="underline">MA in Literature</span> at the University of
        York with a focus in video games and their uniqueness in terms of
        storytelling. Before that I studied{" "}
        <span className="underline">Comparative Literature</span> and{" "}
        <span className="underline">International Relations</span> at Koç
        University in Istanbul, Turkey.
      </p>
    </main>
  )
}

export default HomePage
