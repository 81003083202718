import React from "react"
import { Helmet } from "react-helmet"
import Header from "../components/Header"
import Footer from "../components/Footer"
import HomePage from "../components/HomePage"
import "../styles/global.css"

const Home = () => {
  return (
    <div className="wrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="author" content="Alp Kahvecioglu"></meta>
        <meta
          name="description"
          content="portfolio home page created by alp kahvecioglu"
        ></meta>
        <title>alp kahvecioglu -- portfolio</title>
        <link rel="canonical" href="https://enpassant.me" />
        <html lang="en" />
      </Helmet>
      <Header />
      <HomePage />
      <Footer />
    </div>
  )
}

export default Home
